import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Chip, CardContent, Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { InviteWorkerModal } from './InviteWorkerModal';
import { LocationState } from 'src/pages/WorkerProfileDetails/WorkerProfileDetails.types';

import { JobDetailsParams } from './JobDetails.types';
import { useGetJobById, useCalculateJobWage } from 'src/api/hooks/useJob';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';
import { Job } from 'src/DTO/Job.type';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { JOB_OFFER_STATUS } from 'src/DTO/JobOfferStatus.enum';

export const useStyles = makeStyles((theme: any) => ({
  workerName: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.secondary,
    fontSize: '0.6rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.text.secondary,
    fontSize: '0.6rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  workersContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxHeight: theme.spacing(14),
    overflow: 'auto',
  },
  workerContainer: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),

    '& .MuiChip-root': {
      cursor: 'pointer',
    },
  },
}));

export const JobDetails: React.FC = () => {
  const { t } = useTranslation();
  const { push, location } = useHistory<LocationState>();
  const { jobId } = useParams() as JobDetailsParams;
  const classes = useStyles();

  const isEdit = location.state ? location.state.edit : false;
  //const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [inviteWorkerModalOpen, setInviteWorkerModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [calculateJobWage, { data }] = useCalculateJobWage();

  const [job, setJob] = useState<Job>();

  const [getJobById] = useGetJobById({
    onComplete: res => {
      if (res) {
        setJob(res);
        calculateJobWage({
          variables: {
            hourlyGrossWage: res.hourlyGrossWage,
            monthlyHours: Number(res.monthlyHours),
          },
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobDetailsScreen.error'),
      });
    },
  });

  useEffect(() => {
    getJobById({ variables: { id: jobId } });
  }, []);

  if (!job) {
    return null;
  }

  const handleOpenWorkerProfile = (workerData: WorkerProfile) => {
    push(`worker/${workerData.id}`);
  };

  return (
    <>
      <PageHeader
        pageTitle={t('jobDetailsScreen.title', { name: job.role.name })}
        buttons={[
          {
            buttonLabel: t('jobDetailsScreen.buttonLabel'),
            onClick: () => console.log('invite clicked'),
          },
          // {
          //   buttonLabel: t('jobDetailsScreen.terminate'),
          //   onClick: () => console.log('clicked'),
          // },
          {
            buttonLabel: t('jobDetailsScreen.inviteWorker'),
            onClick: () => setInviteWorkerModalOpen(true),
            disabled: !JOB_STATUS.ACTIVE,
          },
        ]}
      />
      <Grid container>
        <Grid style={{ width: '33%' }}>
          <DetailsForm
            inputFields={[
              { value: job.id, label: t('jobDetailsScreen.form.id'), disabled: true },
              {
                value: job.role.name,
                label: t('jobDetailsScreen.form.roleName'),
                disabled: true,
              },
              {
                value: job.hourlyGrossWage,
                label: t('jobDetailsScreen.form.hourlyNetWage'),
                disabled: true,
              },
              {
                value: job.monthlyHours,
                label: t('jobDetailsScreen.form.monthlyHours'),
                disabled: true,
              },
              {
                value: Math.round(job.monthlyHours / 4),
                label: t('jobDetailsScreen.form.weeklyHours'),
                disabled: true,
              },
              {
                value: data?.wageUnder25.hourlyGrossAmount || '',
                label: t('jobDetailsScreen.form.hourlyGrossAmount'),
                disabled: true,
              },
              {
                value: data?.wageUnder25.hourlyNetAmount || '',
                label: t('jobDetailsScreen.form.hourlyNetAmount'),
                disabled: true,
              },
              {
                value: data?.wageUnder25.hourlyPersonalTaxAmount || '0',
                label: t('jobDetailsScreen.form.hourlyPersonalTaxAmount'),
                disabled: true,
              },
              {
                value: data?.wageUnder25.hourlyCommissionAmount || '',
                label: t('jobDetailsScreen.form.hourlyCommissionAmount'),
                disabled: true,
              },
              {
                value: data?.wageUnder25.invoicedHourlyAmount || '',
                label: t('jobDetailsScreen.form.invoicedHourlyAmount'),
                disabled: true,
              },
              {
                value: data?.wageOver25.hourlyGrossAmount || '',
                label: t('jobDetailsScreen.form.hourlyGrossAmount'),
                disabled: true,
              },
              {
                value: data?.wageOver25.hourlyNetAmount || '',
                label: t('jobDetailsScreen.form.hourlyNetAmount'),
                disabled: true,
              },
              {
                value: data?.wageOver25.hourlyPersonalTaxAmount || '',
                label: t('jobDetailsScreen.form.hourlyPersonalTaxAmount'),
                disabled: true,
              },
              {
                value: data?.wageOver25.hourlyCommissionAmount || '',
                label: t('jobDetailsScreen.form.hourlyCommissionAmount'),
                disabled: true,
              },
              {
                value: data?.wageOver25.invoicedHourlyAmount || '',
                label: t('jobDetailsScreen.form.invoicedHourlyAmount'),
                disabled: true,
              },
            ]}
          />
        </Grid>
        <Grid style={{ width: '33%' }}>
          <DetailsForm
            inputFields={[
              {
                value: format(new Date(job.startDate), 'yyyy-MM-dd'),
                label: t('jobDetailsScreen.form.startDate'),
                disabled: true,
              },
              {
                value: job.endDate ? format(new Date(job.endDate), 'yyyy-MM-dd') : '',
                label: t('jobDetailsScreen.form.endDate'),
                disabled: true,
              },
              {
                value: job.status,
                label: t('jobDetailsScreen.form.status'),
                disabled: true,
              },
              {
                value: job.role.description,
                label: t('jobDetailsScreen.form.roleDescription'),
                disabled: true,
                multiline: true,
              },
              {
                value: job.role.location,
                label: t('jobDetailsScreen.form.roleLocation'),
                disabled: true,
              },
              {
                value: job.role.status,
                label: t('jobDetailsScreen.form.roleStatus'),
                disabled: true,
              },
              {
                value: job.role.company.id,
                label: t('jobDetailsScreen.form.companyId'),
                disabled: false,
                link: true,
                linkAction: () => push(`/companies/${job.role.company.id}`),
              },
              {
                value: job.role.company.name,
                label: t('jobDetailsScreen.form.companyName'),
                disabled: true,
              },

              {
                value: job.jobType,
                label: t('jobDetailsScreen.form.jobType'),
                disabled: true,
              },
              {
                value: job.timeSchedule,
                label: t('jobDetailsScreen.form.timeSchedule'),
                disabled: true,
              },
              {
                value: job.startNow,
                label: t('jobDetailsScreen.form.startNow'),
                disabled: true,
              },
              {
                value: job.documents,
                label: t('jobDetailsScreen.form.documents'),
                disabled: true,
              },
              {
                value: job.workerCount,
                label: t('jobDetailsScreen.form.workerCount'),
                disabled: true,
              },
            ]}
          />
        </Grid>
        <Grid style={{ width: '33%' }}>
          <DetailsForm
            inputFields={[
              {
                value: job.genderTypes,
                label: t('jobDetailsScreen.form.genderTypes'),
                disabled: true,
              },
              {
                value: job.hungarianSkillsNeeded,
                label: t('jobDetailsScreen.form.hungarianSkills'),
                disabled: true,
              },
              {
                value: job.recruitmentNeeded,
                label: t('jobDetailsScreen.form.recruitmentType'),
                disabled: true,
              },
              {
                value: job.extraSkills,
                label: t('jobDetailsScreen.form.extraSkills'),
                disabled: true,
              },
              {
                value: job.drivingLicense,
                label: t('jobDetailsScreen.form.drivingLicense'),
                disabled: true,
              },
              {
                value: job.englishSkillsNeeded,
                label: t('jobDetailsScreen.form.englishSkills'),
                disabled: true,
              },
              {
                value: job.bonus,
                label: t('jobDetailsScreen.form.bonus'),
                disabled: true,
              },
              {
                value: job.contactName,
                label: t('jobDetailsScreen.form.contactName'),
                disabled: true,
              },
              {
                value: job.contactPhone,
                label: t('jobDetailsScreen.form.contactPhone'),
                disabled: true,
              },
              {
                value: job.contactEmail,
                label: t('jobDetailsScreen.form.contactEmail'),
                disabled: true,
              },
              {
                value: job.arriveInfo,
                label: t('jobDetailsScreen.form.arriveInfo'),
                disabled: true,
              },
              {
                value: job.equipmentInfo,
                label: t('jobDetailsScreen.form.equipmentInfo'),
                disabled: true,
              },
              {
                value: job.createdAt
                  ? format(new Date(job.createdAt), 'yyyy-MM-dd')
                  : job.createdAt,
                label: t('jobDetailsScreen.form.createdAt'),
                disabled: true,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Box mt={2} mb={2}>
        <Typography>Workers</Typography>
      </Box>
      <Card>
        <CardContent>
          <Box className={classes.workersContainer}>
            {(job.workers?.length === 0 || !job.workers) && job.offers.length === 0 && (
              <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
            )}
            {job.offers.length > 0 &&
              job.offers.map(offer => (
                <Box
                  className={classes.workerContainer}
                  key={offer.id}
                  mr={2}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => handleOpenWorkerProfile(offer.worker)}>
                  <Typography className={classes.workerName}>{offer.worker.name}</Typography>
                  <Chip
                    label={
                      offer.status === JOB_OFFER_STATUS.PENDING
                        ? t('workersScreen.invitedWorkerList.pendingInvite')
                        : t('workersScreen.invitedWorkerList.underReview')
                    }
                    className={classes.warning}
                  />
                </Box>
              ))}
            {job.workers?.length > 0 &&
              job.workers.map(worker => (
                <Box
                  className={classes.workerContainer}
                  key={worker.id}
                  mr={2}
                  onClick={() => handleOpenWorkerProfile(worker)}>
                  <Typography className={classes.workerName}>{worker.name}</Typography>
                  <Chip
                    label={t('workersScreen.invitedWorkerList.acceptedInvite')}
                    className={classes.success}
                  />
                </Box>
              ))}
          </Box>
        </CardContent>
      </Card>
      <InviteWorkerModal
        jobName={job.role.name}
        jobId={job.id}
        isOpen={inviteWorkerModalOpen}
        cancelButtonAction={() => setInviteWorkerModalOpen(false)}
        companyId={job.role.company.id}
      />
    </>
  );
};
