import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Job } from 'src/DTO/Job.type';
import { useActivateJob, useDeActivateJob, useGetJobs } from 'src/api/hooks/useJob';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import {
  DataGrid,
  GridActionsCellItem,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbar,
} from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';
import { DeleteForever, OneK } from '@mui/icons-material';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import { ActivateJobPayload } from 'src/api/hooks/useJob.types';

const headers = (
  t: TFunction,
  activateJob: BaseRequest<ActivateJobPayload>,
  deActivateJob: BaseRequest<ActivateJobPayload>,
) => {
  return [
    {
      headerName: t('jobsScreen.headers.id'),
      field: 'id',
      sorting: false,
      hide: true,
      flex: 1,
    },
    {
      headerName: t('jobsScreen.headers.roleName'),
      field: 'role.name',
      valueGetter: (params: any) => params.row.role.name,
      flex: 1,
    },
    {
      headerName: t('jobsScreen.headers.companyName'),
      field: 'role.company',
      valueGetter: (params: any) => params.row.role.company.name,
      flex: 1,
    },
    {
      headerName: t('jobsScreen.headers.id'),
      field: 'role.company.id',
      sorting: false,
      valueGetter: (params: any) => params.row.role.company.id,
      hide: true,
      flex: 1,
    },
    {
      headerName: t('jobsScreen.headers.id'),
      field: 'worker.ids',
      sorting: false,
      valueGetter: (params: any) => params.row.workers.map((w: any) => w.id).join(' '),
      hide: true,
      flex: 1,
    },
    { headerName: t('jobsScreen.headers.hourlyWage'), field: 'hourlyGrossWage', flex: 1 },
    { headerName: t('jobsScreen.headers.monthlyHours'), field: 'monthlyHours', flex: 1 },
    {
      headerName: t('jobsScreen.headers.status'),
      field: 'status',
      render: (rowData: Job) => (
        <Typography style={{ color: rowData.status === JOB_STATUS.ACTIVE ? 'green' : 'red' }}>
          {rowData.status}
        </Typography>
      ),
      flex: 1,
    },
    {
      headerName: t('jobsScreen.headers.workerCount'),
      field: 'workerCount',
      sorting: false,
      valueGetter: (params: any) =>
        `${params.row.workers.length}/${params.row.applications.length}/${params.row.offers.length}/${params.row.workerCount}`,
      flex: 1,
    },
    {
      headerName: t('generic.actions'),
      type: 'actions',
      field: 'actions',
      filterable: false,
      getActions: (params: GridRenderCellParams<Date>) => [
        <GridActionsCellItem
          key={1}
          showInMenu
          onClick={() => {
            activateJob({ variables: { id: params.row.id } });
          }}
          icon={<OneK />}
          label="Activate"
        />,
        <GridActionsCellItem
          key={2}
          showInMenu
          onClick={() => {
            deActivateJob({ variables: { id: params.row.id } });
          }}
          icon={<DeleteForever />}
          label="Deactivate"
        />,
      ],
      flex: 1,
    },
  ];
};

export const JobList: React.FC = () => {
  const { t } = useTranslation();
  const [jobs, setJobs] = useState<Job[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getJobs] = useGetJobs({
    onComplete: res => {
      if (res) {
        setJobs(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileListScreen.error'),
      });
    },
  });

  const [activateJob] = useActivateJob({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.authJob.success'),
        });
        getJobs();
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.authJob.error'),
      });
    },
  });

  const [deActivateJob] = useDeActivateJob({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.authJob.success'),
        });
        getJobs();
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.authJob.error'),
      });
    },
  });

  useEffect(() => {
    getJobs();
  }, []);

  const navigateToDetails = (id: string) => {
    return window.open(`/jobs/${id}`, '_blank');
  };

  const { entity, id } = useParams() as { entity: string; id: string };

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  useEffect(() => {
    if (!isEmpty(id) && entity === 'company') {
      setFilterModel({
        items: [
          {
            columnField: 'role.company.id',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
    if (!isEmpty(id) && entity === 'worker') {
      setFilterModel({
        items: [
          {
            columnField: 'worker.ids',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
  }, [id]);

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        filterModel={filterModel}
        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        rows={jobs ? jobs : []}
        columns={headers(t, activateJob, deActivateJob)}
        onRowClick={data => {
          navigateToDetails(data.row.id);
        }}
        loading={!jobs}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
