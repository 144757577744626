import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
} from '@mui/material';
import { useGenerateFGDeclaration, useUpdateDeclaration } from 'src/api/hooks/useWorker';

import { PopUpEnum } from 'src/stores/NotificationStore';

import { useGetNewStudentNotifierXML } from 'src/api/hooks/useXmlService';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { AddAlert, DataUsage, RecentActors } from '@mui/icons-material';
import { useStoreActions } from 'src/stores';

export type QuickFilterProps = {
  waitingForDeclaration: boolean;
  waitingForForeignDeclaration: boolean;
};

export type WorkerProfileToolBarProps = {
  selectedWorkers: string[];
  quickFilter: QuickFilterProps;
  isFilterBarOpen: boolean;
  setQuickFilter: (data: QuickFilterProps) => void;
  setIsFilterBarOpen: (data: boolean) => void;
  refreshData: () => void;
  isMainLoading: boolean;
};

export const WorkerProfileToolBar: React.FC<WorkerProfileToolBarProps> = ({
  selectedWorkers,
  quickFilter,
  setQuickFilter,
  isFilterBarOpen,
  setIsFilterBarOpen,
  refreshData,
  isMainLoading,
}) => {
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [generateFGDPDF, { loading: FGDPDFLoading }] = useGenerateFGDeclaration({
    onComplete: res => {
      if (res && res) {
        try {
          const blob = new Blob([res]);
          saveAs(blob, `foreignDeclaration-${moment().format('YYYY-MM-DD')}.pdf`);
        } catch (error) {
          console.error(error);
          setPopUpData({
            isOpen: true,
            type: PopUpEnum.error,
            message: t('alerts.workerProfileXMLGenerate.cantDownload'),
          });
        }
      }
    },
    requestConfig: {
      responseType: 'blob',
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileXMLGenerate.error'),
      });
    },
  });

  const [updateDeclaration, { loading: declarationLoading }] = useUpdateDeclaration({
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileXMLGenerate.error'),
      });
    },
    onComplete: () => {
      refreshData();
    },
  });

  const [getXml, { loading }] = useGetNewStudentNotifierXML({
    onComplete: res => {
      if (res && res.file) {
        try {
          const blob = new Blob([res.file], { type: 'text/xml' });
          saveAs(blob, 'xml-12.xml');
        } catch (error) {
          console.error(error);
          setPopUpData({
            isOpen: true,
            type: PopUpEnum.error,
            message: t('alerts.workerProfileXMLGenerate.cantDownload'),
          });
        }
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileXMLGenerate.error'),
      });
    },
  });

  const isLoading = FGDPDFLoading || loading || declarationLoading || isMainLoading;

  return (
    <Box mt={1} ml={2}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <GridToolbar />
        </Grid>
        <Grid item textAlign="right" xs={6}>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsFilterBarOpen(!isFilterBarOpen);
            }}
            startIcon={<DataUsage />}>
            {isFilterBarOpen
              ? t('workerProfilesScreen.actions.closeFilterBar')
              : t('workerProfilesScreen.actions.openFilterBar')}
          </Button>
        </Grid>
        {isFilterBarOpen && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => {
                    getXml({
                      variables: {
                        workerIds: selectedWorkers,
                      },
                    });
                  }}
                  disabled={!(selectedWorkers.length > 0) || isLoading}
                  startIcon={<DataUsage />}>
                  {t('workerProfilesScreen.actions.generateXml')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    generateFGDPDF({
                      variables: {
                        workerIds: selectedWorkers,
                      },
                    })
                  }
                  disabled={!(selectedWorkers.length > 0) || isLoading}
                  style={{ marginTop: 12 }}
                  color="success"
                  startIcon={<RecentActors />}>
                  {t('workerProfilesScreen.actions.generateForeignPDFDelcaration')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    updateDeclaration({
                      variables: {
                        isForeign: false,
                        workerIds: selectedWorkers,
                      },
                    });
                  }}
                  disabled={!(selectedWorkers.length > 0) || isLoading}
                  startIcon={<AddAlert />}>
                  {t('workerProfilesScreen.actions.recordDeclaration')}
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    updateDeclaration({
                      variables: {
                        isForeign: true,
                        workerIds: selectedWorkers,
                      },
                    });
                  }}
                  disabled={!(selectedWorkers.length > 0) || isLoading}
                  startIcon={<AddAlert />}>
                  {t('workerProfilesScreen.actions.recordForeignDeclaration')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={quickFilter.waitingForDeclaration}
                        onClick={() => {
                          setQuickFilter({
                            ...quickFilter,
                            waitingForDeclaration: !quickFilter.waitingForDeclaration,
                          });
                        }}
                      />
                    }
                    label="Waiting for declaration"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={quickFilter.waitingForForeignDeclaration}
                        onClick={() => {
                          setQuickFilter({
                            ...quickFilter,
                            waitingForForeignDeclaration: !quickFilter.waitingForForeignDeclaration,
                          });
                        }}
                      />
                    }
                    label="Waiting for FOREIGN declaration"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
